/** @jsx jsx */
import { jsx, Heading } from "theme-ui";
import * as React from "react";
import { PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { transparentize } from "polished";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout";
import Seo from "@lekoarts/gatsby-theme-jodie/src/components/seo";
import useQuadriJson, { Quadro } from "../hooks/use-quadri-json";
import { useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Image,
  ListGroup,
  ListGroupItem,
  Table,
} from "react-bootstrap";
import useQuadriImages, { ImgQuadri } from "../hooks/use-quadri-images";
import { Oggetto } from "../hooks/use-oggetti-json";
import { ImgOggetti } from "../hooks/use-oggetti-images";
type DataProps = {
  project: {
    body: string;
    excerpt: string;
    color: string;
    date: string;
    slug: string;
    title: string;
    shortTitle: string;
    category: string;
    cover: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
    };
  };
  images: {
    nodes: {
      name: string;
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    }[];
  };
};

const Project: React.FC<PageProps<DataProps>> = ({
  data: { project, images },
  location,
}) => {
  // const { quadri } = useQuadriJson();
  const { data } = (location.state as any) ?? [];
  const { immagini } = (location.state as any) ?? [];
  const [quadriData, setQuadriData] = React.useState<Quadro[]>([]);
  const [oggettiData, setOggettiData] = React.useState<Oggetto[]>([]);
  const [imgQuadriData, setImgQuadriData] = React.useState<ImgQuadri[]>([]);
  const [imgOggettiData, setImgOggettiData] = React.useState<ImgOggetti[]>([]);
  useEffect(() => {
    if (location.pathname.includes("oggettistica")) {
      setOggettiData(data);
      setImgOggettiData(immagini);
    } else {
      setQuadriData(
        data
        // quadri.filter((q) => q.categoria === location.pathname.split("/")[1])
      );

      setImgQuadriData(
        immagini
        // nodes.filter(
        //   location.state.immagini!
        //   // (img) => img.relativeDirectory === location.pathname.split("/")[1]
        // )
      );
    }
  }, [location.pathname]);

  const findImageQuadri = (name) => {
    const imageToRender = imgQuadriData.find((img) => img.name === name);
    console.log("Image to Render:", imageToRender);
    if (imageToRender === undefined)
      return <GatsbyImage key={""} alt={""} image={null} />;
    return (
      <GatsbyImage
        key={imageToRender.name}
        alt={imageToRender.name}
        image={imageToRender.childImageSharp.gatsbyImageData}
      />
    );
  };

  const findImageOggetto = (name) => {
    const imageToRender = imgOggettiData.find((img) => img.name === name);
    if (imageToRender === undefined)
      return <GatsbyImage key={""} alt={""} image={null} />;
    return (
      <GatsbyImage
        key={imageToRender.name}
        alt={imageToRender.name}
        image={imageToRender.childImageSharp.gatsbyImageData}
      />
    );
  };
  return (
    <Layout color={project.color || undefined}>
      <Seo
        title={project.title}
        description={project.excerpt}
        pathname={location.pathname}
        image={project.cover.childImageSharp.resize.src}
      />
      <div sx={{ variant: `content.project` }}>
        <div
          sx={{
            fontSize: 2,
            textTransform: `uppercase`,
            letterSpacing: `wider`,
            mb: 2,
          }}
        >
          {project.category}
        </div>
        <Heading as="h1" variant="styles.h1" sx={{ mt: 0 }}>
          {project.title}
        </Heading>
        <div sx={{ maxWidth: `70ch` }}>
          <MDXRenderer>{project.body}</MDXRenderer>
        </div>
      </div>
      <div sx={{ backgroundColor: transparentize(0.9, project.color) }}>
        <div sx={{ variant: `content.imageList` }}>
          <Container>
            {location.pathname.includes("oggettistica")
              ? oggettiData.map((o, index) => (
                  <div className="mt-5 border-t-2 border-black">
                    <Row key={index} className="m-1">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>Evento</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{o.nome}</td>
                            <td>{o.evento || "-"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>

                    {o.foto.map((f, i) => (
                      <>
                        <Row>
                          <Col className="text-center mb-3">
                            {" "}
                            {f.descrizione}
                          </Col>
                        </Row>
                        <Row>
                          {f.src.map((src, i) => (
                            <Col className="flex justify-center">
                              {findImageOggetto(src)}
                            </Col>
                          ))}
                        </Row>
                      </>
                    ))}
                  </div>
                ))
              : quadriData.map((q, index) => (
                  <div className="mt-5 border-t-2 border-black">
                    <Table responsive="sm">
                      <thead>
                        <tr className="text-center">
                          <th>Titolo</th>
                          <th>Descrizione</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td>{q.titolo}</td>
                          <td>{q.descrizione || "-"}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Table responsive>
                      <thead>
                        <tr className="text-center">
                          <th>Dimensioni</th>
                          <th>Disponibilità</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td>{q.dimensioni || "-"}</td>
                          <td>{q.prop || "-"}</td>
                        </tr>
                      </tbody>
                    </Table>
                    {/* <Row className="font-semibold">
                      <Col> Titolo </Col>
                      <Col> Descrizione </Col>
                      <Col> Dimensioni </Col>
                      <Col> Disponibilità </Col>
                    </Row>
                    <Row>
                      <Col> {q.titolo}</Col>
                      <Col> {q.descrizione || "-"}</Col>
                      <Col> {q.dimensioni || "-"}</Col>
                      <Col> {q.prop || "-"}</Col>
                    </Row> */}

                    <Row>
                      <Col>{findImageQuadri(q.foto)}</Col>
                    </Row>
                  </div>
                ))}
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default Project;
